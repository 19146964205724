/**
 * TPM公共锚点方法库
 */
const AnchorFn = {
    /**
     * Test demo
     * @param params 
     */
    query(params) {
        console.log('String Fn Callback:', params);
    }


}
export default AnchorFn;