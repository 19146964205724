<template>
    <div class="wheelBox">
        <div class="mask_top absolute"></div>
        <div class="mask_bottom absolute" :style="{ height: rowH, top: rowTop }"></div>
        <div class="wheel_wrapper" ref="wrapper">
            <div class="wheel_scroller" :style="{ marginTop: rowTop }">
                <template v-for="(item, index) in dataList">
                    <div 
                        class="wheel_item" 
                        :style="{ height: rowH, lineHeight: rowH }" 
                        :key="index" 
                        :class="{ isActive: selectedIndex == index }">
                            {{ item.txt }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import BScroll from "better-scroll";
export default {
    name: "wheel",
    model: {
        prop: "value",
        event: "valueListener"
    },
    props: {
        // 数据源
        dataList: {
            type: Array,
            default: () => []
        },
        // 默认选中的索引
        value: {
            type: [Object, String, Number],
            default: "",
        },
        // 每一项偏转的角度
        rotate: {
            type: Number,
            default: 18
        },
        // 选中行距离顶部的行数
        top: {
            type: Number,
            default: 2
        },
        // 单行的高度
        rowHeight: {
            type: Number,
            default: 40
        }
    },
    watch: {
        // 父组件的值变化改变当前选中的值
        value() {
            this.initSelectedIndex();
        },
        // 将选择的值通知给父组件
        selectedIndex() {
            const selectedValue = this.dataList[this.selectedIndex || 0] ? this.dataList[this.selectedIndex || 0].txt : this.dataList[0].txt;
            this.$emit("valueListener", selectedValue)
        },
        // 数组变化，刷新better-scroll
        dataList() {
            this.refresh();
        }
    },
    computed: {
        // 单行高度
        rowH() {
            return `${this.rowHeight}px`
        },
        // 选中行距离滑动容器顶部的实际距离
        rowTop() {
            return `${this.top * this.rowHeight}px`
        }
    },
    data() {
        return {
            wheels: undefined,
            selectedIndex: 0, // 默认选中的索引
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.initSelectedIndex();
            this._createWheel().enable();
        });
    },
    methods: {
        // 初始化better-scroll
        _createWheel() {
            if (!this.wheels) {
                const wheel = (this.wheels = new BScroll(this.$refs.wrapper, {
                    startY: 0,
                    click: true,
                    scrollX: false,
                    scrollY: true,
                    eventPassthrough: "horizontal",
                    wheel: {
                        selectedIndex: this.selectedIndex,
                        wheelWrapperClass: "wheel_scroller", // 滑动的壳子
                        wheelItemClass: "wheel_item", // 滑动里面的子元素，注意里面必须全部是一样的
                        rotate: this.rotate,
                    },
                    observeDOM: false,
                }));
                // 滚动结束时触发
                wheel.on("scrollEnd", () => {
                    //滚动完成之后获取当前选取的索引值
                    this.selectedIndex = wheel.getSelectedIndex();
                });
                // 初始化选中
                this.selectedIndex = wheel.getSelectedIndex();
                this.$emit("valueListener", this.dataList[this.selectedIndex || 0].txt)
            } else {
                // 实际刷新在这里
                this.wheels.refresh();
                // 列表更新后需要更新v-model绑定的当前索引
                this.selectedIndex = this.wheels.getSelectedIndex();
                this.$emit("valueListener", this.dataList[this.selectedIndex || 0].txt)
            }
            return this.wheels;
        },
        // 获取索引
        initSelectedIndex() {
            const index = this.dataList.findIndex(e => e.txt == this.value);
            this.selectedIndex = index == -1 ? 0 : index;
        },
        // 刷新，注意，当数组变化时，必须加$nextTick，否则无效
        refresh() {
            this.$nextTick(() => {
                this.initSelectedIndex();
                this._createWheel();
                this.wheels.scrollTo(0, 0, 100, 'ease-in-out')
            })
        }
    },
};
</script>
<style lang="scss" scoped>
    .wheelBox {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: relative;
        .absolute {
            position: absolute;
        }
        .mask_top {
            height: 80px;
            width: 100%;
            top: 0;
            border-bottom: 1px solid #EEEEEE;
        }
        .mask_bottom {
            // height: 40px;
            width: 100%;
            // top: 80px;
            border-bottom: 1px solid #EEEEEE;
            z-index: -1;
        }
        .wheel_wrapper {
            touch-action: none;
            height: 100%;
            overflow: hidden;
            .wheel_scroller {
                // margin-top: 80px;
                .wheel_item {
                    // height: 40px;
                    // line-height: 40px;
                    text-align: center;
                    color: #CCCCCC;
                }
                .isActive {
                    color: black;
                    font-size: 0.16rem;
                }
            }
        }
    }
</style>