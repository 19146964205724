/* eslint-disable */
import {
    /**iAuthorization, */
    iHtml
} from '@/core'
// iAuthorization.install()

import Vue from 'vue';
window.Vue = Vue
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import './core/rem/index'
import './assets/public.scss';
import 'smart-core-util/lib/index.css'
import '@/utils/nativeJsBridge'
import "babel-polyfill"
import "mint-ui/lib/style.css"
import SetUris from "@/plugins/set-uris.js"
import AppInitialization from "@/plugins/app-initialization.js"
import CacheData from "@/plugins/cache-data.js"
import getTenantIds from "@/plugins/getTenantIds.js"
import {
    fixAndroidInput,
} from './core';
//全局引入组件
import introduce from './introduce'
introduce.init();

// ARMS监控
import "@/plugins/alife-logger.js";

import $iDatePicker from '@/components/iDatePicker/plugin.js'
Vue.prototype.$iDatePicker = new $iDatePicker({
    Vue
});

import GetApiVersion from '@/service/getApiVersion.js';


import { SmartStorage } from 'smart-core-util'
Vue.prototype.SmartStorage = SmartStorage
import mealInit from "@/plugins/meal-init.js"
Vue.prototype.$mealInit = mealInit


import _ from 'lodash'
Vue.prototype._ = _

import delay from '@/utils/delay'
Vue.prototype.delay = delay

// ui组件
import SmartUI from 'smart-ui'
import 'smart-ui/lib/index.css'
Vue.use(SmartUI);

import validate from '@/utils/validate'
Vue.prototype.validate = validate

import toast from '@/utils/toast'
Vue.prototype.toast = toast

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)

import iSignalr from 'smart-signalr'
Vue.prototype.iSignalr = iSignalr

import ScrollBar from './components/ScrollBar/ScrollBar.vue'
Vue.component('ScrollBar', ScrollBar);

import {
    SmartRem
} from 'smart-core-util'
SmartRem.install(document, window)

Vue.prototype.ihtml = iHtml

Vue.use(fixAndroidInput);

import publicFun from './utils/publicFun'
Vue.prototype.iJsBridge = publicFun;

import { Field } from 'vant';
Vue.use(Field);
import VueBarcode from '@xkeshi/vue-barcode'; //导入条形码插件
Vue.component('barcode', VueBarcode);

Vue.config.productionTip = false;
Vue.config.silent = true

/**
 * @param {object} o 原对象 
 * @returns 新对象
 */
Vue.prototype.jsonCopy = o => {
    return JSON.parse(JSON.stringify(o))
};


Promise.resolve().then(() => {
    return CacheData.install()
}).then(() => {
    return getTenantIds.install()
}).then(() => {
    return AppInitialization.install()
}).then(() => {
    return SetUris.install()
}).then(() => {
    return GetApiVersion.install()
}).then(() => {
    console.warn('====正常启动App====')
    window.ccApp = new Vue({
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
})