import { SmartStorage } from 'smart-core-util'
import { miceService } from './miceService'
import {
	getCookie, setCookie
} from 'tiny-cookie';

export default class GetApiVersion {

	static install() {
		return new Promise(function (resolve, reject) {
			let params = {
				collection: "cfg-api-version",
				filter: {
					platform: "app",
					tenantCode: getCookie("tenant_code"),
					userIds: {
						$in: [getCookie("userId"), "*"]
					},
					type: "imeal"
				},
				projection: {
					apiVersion: 1,
					userIds: 1
				},
				sort: {
					sort: 1
				}
			}
			miceService.getsettings(params).then(response => {
				console.log('====get api version==== ', response.content)
				if (response && response.success) {
					resolve(response)
					SmartStorage.set("apiVersion", response.content.apiVersion)
					setCookie("apiVersion", response.content.apiVersion)
				} else {
					resolve(response)
				}
			}).catch(error => {
				resolve(error)
			});
		})
	}

}