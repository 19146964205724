import { SmartStorage } from 'smart-core-util'
import { miceService } from '../service/miceService'
import {
    getCookie
} from 'tiny-cookie';
var enviroment = process.env.NODE_ENV; // 线上
export default class SetUris {
    static install() {
        return new Promise(function (resolve) {

            if (SmartStorage.get('Uris')) {
                resolve(true)
                return
            }

            let type = '';
            let tenant_code = getCookie('tenant_code') || SmartStorage.get('tenant_code')

            if (tenant_code == 'crossevent') {
                type = SmartStorage.get('checkTenantId') ? '_mice' : ''
            }

            if (process.env.VUE_APP_NPM_TYPE == 'uat') {
                type = ''
                enviroment = 'uat'
            }

            let url = `https://spkg.smartmice.cn/configuration/iapp-config/${tenant_code}${type}/${enviroment}/uris.json`
            let gray = getCookie("gray")
            if (gray) {
                url = `https://spkg.smartmice.cn/configuration/iapp-config/${tenant_code}${type}/${enviroment}/uris_v2.json`
            }
            let params = {
                params: {
                    rnd: new Date().getTime()
                }
            }

            miceService.Get(url, params).then(response => {
                var tmp = { 'Uris': response.uris || {} };
                SetUris.setUris(tmp)
                resolve(response)
            }).catch(error => {
                resolve(error)
            })
        })
    }
    // 设置Uris
    static setUris(uris = {}) {
        sessionStorage.setItem("Uris", JSON.stringify(uris))
    }
}