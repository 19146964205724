import Vue from 'vue';

let modules = {
    list: [
        {
            name: 'Svgs',//svg
            model: resolve => require(['./components/Svgs.vue'], resolve)
        },
        {
            name: 'Pop',//弹框
            model: resolve => require(['./components/Pop.vue'], resolve)
        },
        {
            name: 'Btn',//按钮
            model: resolve => require(['./components/Btn.vue'], resolve)
        },
        {
            name: 'DatePicker',//日期
            model: resolve => require(['./components/DatePicker/DatePicker.vue'], resolve)
        },
        {
            name: 'TimePicker',//时间
            model: resolve => require(['./components/TimePicker.vue'], resolve)
        },
        {
            name: 'MInput',//输入框
            model: resolve => require(['./components/MInput.vue'], resolve)
        },
        // {
        //     name: 'AlertMessage',//吐司弹框
        //     model: resolve => require(['./components/AlertMessage.vue'], resolve)
        // },
        {
            name: 'Select',//选择器
            model: resolve => require(['./components/Select.vue'], resolve)
        },
        {
            name: 'MessageBox',//消息提示
            model: resolve => require(['./components/MessageBox.vue'], resolve)
        },
        {
            name: 'BrowserLoader',//消息提示
            model: resolve => require(['./components/BrowserLoader.vue'], resolve)
        },
        {
            name: 'Header',//头部
            model: resolve => require(['./components/header.vue'], resolve)
        },
        // {
        //     name: 'NavBar',//下方切换导航
        //     model: resolve => require(['./components/NavBar.vue'], resolve)
        // },
        {
            name: 'SearchCity',//城市
            model: resolve => require(['./components/SearchCity/SearchCity.vue'], resolve)
        },
        // {
        //     name: 'ScrollBar',//滚动
        //     model: resolve => require(['./components/ScrollBar/ScrollBar.vue'], resolve)
        // },
        {
            name: 'SPicker',//选择器(只有滚动部分)
            model: resolve => require(['./components/SPicker/SPicker.vue'], resolve)
        },
        {
            name: 'ScrollPicker',//选择器(只有滚动部分)新选择器 替换SPicker
            model: resolve => require(['./components/ScrollPicker.vue'], resolve)
        },
        // {
        //     name: 'Field',//单列选项条
        //     model: resolve => require(['./components/Field.vue'], resolve)
        // },
        {
            name: 'NoData',//无数据
            model: resolve => require(['./components/NoData.vue'], resolve)
        },
        {
            name: 'Skeleton',//加载中骨架显示
            model: resolve => require(['./components/Skeleton.vue'], resolve)
        },
        // {
        //     name: 'FixedNum',//保留小数
        //     model: resolve => require(['./components/FixedNum.vue'], resolve)
        // },
        // {
        //     name: 'AboutTenant',//tenant相关操作集成
        //     model: resolve => require(['./components/AboutTenant.vue'], resolve)
        // },
        // {
        //     name: 'SearchSelect',//搜索多选
        //     model: resolve => require(['./components/SearchSelect/SearchSelect.vue'], resolve)
        // },
        {
            name: 'SearchRow',//搜索框
            model: resolve => require(['@/components/SearchRow.vue'], resolve)
        },
        // {
        //     name: 'RadioSlider',//是/否选择滑块
        //     model: resolve => require(['./components/RadioSlider.vue'], resolve)
        // },
        // {
        //     name: 'ProcessModel',//进程列表
        //     model: resolve => require(['./components/ProcessModel.vue'], resolve)
        // },
        // {
        //     name: 'TagSign',//标签
        //     model: resolve => require(['./components/TagSign.vue'], resolve)
        // },
        {
            name: 'HospitalPop',//医院
            model: resolve => require(['@/components/HospitalPop.vue'], resolve)
        },
        {
            name: 'Tips',//提示
            model: resolve => require(['@/components/Tips.vue'], resolve)
        },
        {
            name: 'StarModel',//星级打分
            model: resolve => require(['@/components/StarModel.vue'], resolve)
        },
        {
            name: 'AddBtn',//添加/创建 右下角固定型按钮
            model: resolve => require(['@/components/AddBtn.vue'], resolve)
        },
        {
            name: 'MapSearch',//地图搜索
            model: resolve => require(['@/components/MapSearch/mapSearch.vue'], resolve)
        },
        {
            name: 'AttentionTips',//注意事项
            model: resolve => require(['@/components/AttentionTips.vue'], resolve)
        }
    ],
    init() {
        for (let i = 0; i < modules.list.length; i++) {
            Vue.component(modules.list[i].name, modules.list[i].model);
        }
    }
}
export default modules;