import { SmartHttp } from './packages'
// import { Toast } from 'mint-ui';
import toast from '@/utils/toast'
import { indicator } from 'smart-indicator'
import {
    SmartStorage
} from './packages'



const request = new SmartHttp({
    baseURL: process.env.VUE_APP_GATEWAY,
}).request;


export const miceService = {
    /**
     * 公共post
     */
    common: (url, params, conf) => {
        return request.post(url, params || {}, conf || {});
    },
    // get公共方法
    Get: (url, params) => {
        return request.get(url, params);
    },
    // 数据持久化-保存
    saveCacheData: (params) => {
        let data = {
            ...params,
            Prefix: "jwts_"
        }
        return request.post('/api/statestore/redis/create', data);
    },
    // 小秘书下单前卡控
    execute: (params) => {
        return request.post('/api/tpm/node/execute', params);
    },
    // 数据持久化-获取
    queryCacheData: (params) => {
        return request.post('/api/statestore/redis/query', params);
    },
    // 获取用户
    getMe: (params) => {
        return request.post("/foundation/passport/IUserService/GetMe", params);
    },
    // 列表添加需求按钮是否可用
    checkCanNewItem: (params) => {
        return request.get("/api/event/Proposal/CheckCanNewItem", { params: params });
    },
    // 按钮配置
    newItem: (params) => {
        return request.post("/api/tpm/cfg/QueryElements", params);
    },
    // 获取外出用餐列表
    getMealList: (params) => {
        return request.get("/api/Meal/Meal/GetItems", { params: params });
    },
    // 获取外卖列表
    getTakeoutList: (params) => {
        return request.get("/api/Meal/Takeout/GetItems", { params: params });
    },
    // 获取商超列表
    getMarketList: (params) => {
        return request.get("/api/Meal/Takeout/GetItems", { params: params });
    },
    // 删除外出用餐需求
    deleteMealItem: (params) => {
        return request.post("/api/Meal/Meal/deleteItem", params);
    },
    // 取消外出用餐需求
    cancelMealItem: (params) => {
        return request.post("/api/Meal/Meal/CancelItem", params);
    },
    // 删除外卖需求
    deleteTakeoutItem: (params) => {
        return request.post("/api/Meal/Takeout/deleteItem", params);
    },
    // 获取美团外卖地址
    getDianPingUrl: (params) => {
        return request.post("/api/category/takeout/takeout/GetDianPingUrl", params);
    },
    // 获取饿了么H5外卖地址
    getElePingUrl: (params) => {
        return request.post("/api/category/meal/ele/EleH5/GetHomePageUrl", params);
    },
    // 刷新美团外卖需求
    refreshDianPingOrder: (params) => {
        return request.post("/api/category/takeout/takeout/RefreshOrder", params);
    },
    // 刷新美团外卖需求
    refreshDianPing: (params) => {
        return request.post("/api/category/meal/meituan/MeiTuan/Refresh", params);
    },
    // 获取饿了么H5详情页
    GetOrderPageUrl: (params) => {
        return request.post("/api/category/meal/ele/EleH5/GetOrderPageUrl", params);
    },
    // 饿了么外卖取消
    eleTakeoutCancel: (params) => {
        return request.post("/api/category/meal/item/Cancel", params);
    },
    getTrackingInfo: (params) => {
        return request.post("/api/category/meal/ele/order/getTrackingInfo", params);
    },
    // 美餐外卖取消
    meicanTakeoutCancel: (params) => {
        return request.post("/api/category/meal/v2/order/CancellOrder", params);
    },
    // 外卖取消
    cancelTakeoutItem: (params) => {
        return request.post("/api/Meal/Takeout/CancelItem", params);
    },
    // 饿了么退款
    eleRefund: (params) => {
        return request.post("/api/category/meal/v2/item/Cancel", params);
    },
    // 新增外卖需求
    saveTakeOutItem: (params) => {
        return request.post("/api/Meal/Takeout/saveItem",
            params,
            {
                headers: {
                    "authMobile": SmartStorage.get('authMobile')
                }
            });
    },
    // 更换商宴通餐厅
    openSYTShopList: (params) => {
        return request.get("/api/external/syt/smartmice/rest", { params: params });
    },
    // 更换餐厅
    changeShop: (params) => {
        return request.post("/api/Meal/Meal/ChangeShop", params);
    },
    // 新增外出用餐需求
    saveMealItem: (params) => {
        return request.post("/api/Meal/Meal/SaveItem",
            params,
            {
                headers: {
                    "authMobile": SmartStorage.get('authMobile')
                }
            });
    },
    // 上传发票时间不一致存说明
    saveItemData: (params) => {
        return request.post("/api/Meal/Meal/SaveItemData", params);
    },
    // 提交结算
    submitSettle: (params) => {
        return request.post("/api/Meal/Meal/SubmitSettle", params);
    },
    // 更新高德餐厅
    SearchShopDetail: (params) => {
        return request.post("/api/mshop/shop/SearchShopDetail", params);
    },
    //
    paySYT: (params) => {
        return request.get("/api/external/syt/smartmice/pay", { params: params });
    },
    // 商宴通验证
    checkSYTAuthed: (params) => {
        return request.post("/api/external/syt/smartmice/checkSYTAuthed", params);
    },
    // 取消商宴通
    cancelorderSYT: (params) => {
        return request.post("/api/external/syt/smartmice/cancelorder", params);
    },
    // 获取美团到店地址
    mealDianPingUrl: (params) => {
        return request.post("/api/category/meal/meituan/shop/login", params);
    },
    // 饿了么创建订单
    eleCreate: (params) => {
        return request.post("/api/external/ele/order/create", params);
    },
    // 饿了么支付
    elepay: (params) => {
        return request.post("/api/external/ele/pay/notice", params);
    },
    // 提交发票
    completeInvoice: (params) => {
        return request.post("/api/category/meal/Invoice/Complete", params);
    },
    // // 查询中智商展二维码信息
    // invoiceGetCompany: (params) => {
    //     return request.get("/api/Invoice/Invoice/GetCompany", { params: params });
    // },
    // 查询中智商展二维码信息
    invoiceGetCompany: (params) => {
        return request.get("/api/category/meal/Invoice/GetCompanyInvoice", { params: params });
    },
    // 提交异常发票
    exceptionInvoice: (params) => {
        return request.post("/api/paper/invoice/Exception/SaveExceptionInvoice", params);
    },
    // 保存发票
    saveInvoice: (params) => {
        return request.post("/api/Invoice/Invoice/SaveInvoice", params);
    },
    // 获取外出用餐详情
    getMealItemDetail: (params) => {
        return request.post("/api/Meal/Meal/GetItemDetail", params);
    },
    // 外卖确认收货
    takeDelivery: (params) => {
        return request.post("/api/Meal/Takeout/TakeDelivery", params);
    },
    // 获取tpm配置
    getOptItems: (params) => {
        return request.post("/api/tpm/cfg/querySettings", params);
    },
    // 更新文件
    saveQtyExpenses: (params) => {
        return request.post("/api/category/meal/Item/SaveQtyExpenses", params);
    },
    // 饿了么外卖新增员工
    addEmployee: (params) => {
        return request.post("/api/aggregatorjmeal/employee/AddEmployee", params);
    },
    // 饿了么再支付
    reToPay: (params) => {
        return request.post("/api/category/meal/ele/Order/SetPaymentStatus", params);
    },
    // 支付宝 我扫商户
    sendPayRequest: (params) => {
        return request.post("/api/services/tenantx/TtProposalExpenseService/SendPayRequest", params);
    },
    // 获取发票
    queryInvoice: (params) => {
        return request.post("/foundation/aliyun/api/oss/query", params);
    },
    // 删除发票
    deleteInvoice: (params) => {
        return request.post("/foundation/aliyun/api/oss/delete", params);
    },
    triggerPaySignal: (params) => {
        return request.post("/api/services/tenantx/TtProposalExpenseService/TriggerPaySignal", params);
    },
    kouBeiConfirmSettle: (params) => {
        return request.post("/api/services/tenantx/TtProposalOrder4MealService/KouBeiConfirmSettle", params);
    },
    requestPayQr: (params) => {
        return request.post("/api/services/tenantx/TtProposalExpenseService/RequestPayQr", params);
    },
    getQrcode: (params) => {
        return request.post("/api/services/tenantx/TtProposalExpenseService/RefreshPayQR", params);
    },
    getTakeoutDetail: (params) => {
        return request.post("/api/category/meal/Item/GetDetail", params);
    },
    // 获取日期列表
    getItemTimeList: (params) => {
        return request.get("/api/category/meal/item/GetItemTimeList", { params: params });
    },
    // 获取饿了么普适化页面
    getElePayPage: (params) => {
        return request.post("/api/external/ele/pay/NologinPay", params);
    },
    // 获取饿了么支付是否成功
    isPaySuccess: (params) => {
        return request.post("/api/external/ele/order/IsPaySuccess", params);
    },
    // 获取发票类型
    invoiceGetTypes: (params) => {
        return request.post("/foundation/dict/api/fndictionary/query", params);
    },
    invoiceQuery: (params) => {
        return request.post("/api/Invoice/Invoice/Query", params);
    },
    // 发票验真
    invoiceVerify: (params) => {
        return request.post("/api/Invoice/Invoice/Verify", params);
    },
    // 删除发票
    deleteInvoice1: (params) => {
        return request.get("/api/Invoice/Invoice/Delete", { params: params });
    },
    // 删除发票附件
    deleteInvoice12: (params) => {
        return request.post("/foundation/aliyun/api/oss/delete", params);
    },
    invoiceMapFiles: (params) => {
        return request.post("/api/Invoice/Invoice/MapFiles", params);
    },
    // 获取会议信息
    getMiceInfo: (params, tenant_code) => {
        if (tenant_code == 'roche') {
            return request.post('/api/tenants/roche/mice/GetEventInfo', params);

        } else {
            return request.post('/api/engine/event/Mice/GetMiceInfo', params);
        }
    },
    // 协会获取会议信息
    GetEventOrder: (params) => {
        return request.post('/api/crossevent/EventOrder/GetEventOrder', params);
    },
    // 获取医院
    getHospitalList: (params) => {
        return request.get("/api/mshop/hospital/query", { params: params });
    },
    getsettings: (params) => {
        return request.post('/api/tpm/cfg/getsettings', params)
    },
    getShopList: (params) => {
        return request.get("/api/mshop/shop/Shops", { params: params });
    },
    getGaoDeShopList: (params) => {
        return request.get("/api/mshop/shop/SearchAMap", { params: params });
    },
    // getGaoDeShopList: (params) => {
    //     return request.post("/api/mshop/shop/SearchAroundShops", params);
    // },
    saveGaoDeshop: (params) => {
        return request.post("/api/mshop/shop/save", params);
    },
    getShopDetail: (params) => {
        return request.get('/api/mshop/shop/get', { params: params })
    },
    getEleDetail: (params) => {
        return request.post('/api/external/ele/restaurant/detail', params);
    },
    getEleType: (params) => {
        return request.post('/api/external/ele/restaurant/getType', params);
    },
    queryRegion: (params) => {
        return request.post('/api/shopkeeper/Region/QueryRegion', params);
    },
    checkItemTypeLocked: (params) => {
        return request.get('/api/Meal/Meal/CheckItemTypeLocked', { params: params });
    },
    // 外出用餐美团获取地址
    meituanLogin: (params) => {
        return request.post('/api/category/meal/meituan/shop/login', params);
    },
    //美团商宴通获取餐厅名称
    QueryShop: (params) => {
        return request.post('/api/Meal/Meal/QueryChangeShop', params);
    },
    SaveChangeShop: (params) => {
        return request.post('/api/Meal/Meal/SaveChangeShop', params);
    },
    // 查询医院
    PagingTenantHospital: (params, header) => {
        return request.post('/api/shopkeeper/Hospital/PagingTenantHospital', params, header);
    },
    // 查询我的城市
    QueryMyCity: (params, header) => {
        return request.post('/api/shopkeeper/ShopRecommend/QueryMyCity', params, header);
    },
    // 查询我的医院
    QueryMyHospitals: (params, header) => {
        return request.post('/api/shopkeeper/ShopRecommend/QueryMyHospitals', params, header);
    },
    // 查询我的餐厅
    QueryMyShops: (params, header) => {
        return request.post('/api/shopkeeper/ShopRecommend/QueryMyShops', params, header);
    },
    // 提交推荐餐厅
    SubmitMyShops: (params, header) => {
        return request.post('/api/shopkeeper/ShopRecommend/SubmitMyShops', params);
    },
    // 提交餐厅
    PostMyShops: (params, header) => {
        return request.post('/api/shopkeeper/ShopRecommend/PostMyShops', params, header);
    },
    // 微信获取验证
    GenerateWechatJsSDKParams: (params) => {
        return request.get('/foundation/passport/WechatMp/GenerateWechatJsSDKParams', { params: params });
    },
    // 微信卡包获取参数
    GetPullInvoiceParam: (params) => {
        return request.get('/foundation/passport/WeChatMp/GetPullInvoiceParam', { params: params });
    },
    // 上传微信卡包  
    getinvoicebatch: (params) => {
        return request.post('/api/ocr/cardPackageInvoice/getinvoicebatch', params);
    },
    // 京东H5
    JDH5Login: (params) => {
        return request.post('/api/jd/login/autoLogin', params,);
    },
    // 京东确认订单
    confirmOrder: (params) => {
        return request.post('/api/jd/orderStatus/confirmOrder', params,);
    },
    // 京东详情
    getJDOrderDetail: (params) => {
        return request.post('/api/jd/orderStatus/getOrderDetail', params,);
    },
    // 支付宝个人授权
    GenerateUserAuthUrl: (params) => {
        return request.get('/rpc/pay/Alipay/GenerateUserAuthUrl', { params: params });
    },
    // 支付宝企业授权
    GenerateMemberAuthUrl: (params) => {
        return request.get('/rpc/pay/Alipay/GenerateMemberAuthUrl', { params: params },);
    },
    // 支付宝授权查询
    CheckUserAuth: (params) => {
        return request.get('/rpc/pay/Alipay/CheckUserAuth', { params: params });
    },
    // 查询是否成功
    CheckPayInfo: (params) => {
        return request.get('/rpc/pay/Alipay/CheckPayInfo', { params: params });
    },
    // 支付宝去支付
    GenerateAlipayUrl: (params) => {
        return request.post(
            '/rpc/pay/Alipay/GenerateAlipayUrl',
            params.params,
            {
                headers: {
                    "alipay-certificate": params.headers
                }
            });
    },
    // 卡控
    queryElements: (params) => {
        return request.post('/api/tpm/cfg/ExecuteRuleNode', params,);
    },
    //山德士 批量审批
    BatchPerformTask: params => {
        return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/BatchPerformTask', params);
    },
    //获取订单按钮
    QueryTaskActions: params => {
        return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/QueryTaskActions', params);
    },
    //订单按钮操作
    PerformTask: params => {
        return request.post('/api/foundation/bpm/foundation/workflow/v2/Task/PerformTask', params);
    },
    //用户反馈
    ExecuteDeciderDapr: params => {
        return request.post('/api/tpm/cfg/ExecuteDeciderDapr', params);
    },
    //电子围栏定位失败存储当前定位信息
    locationFailed: params => {
        return request.post('/api/tpm/node/execute', params);
    },
    //获取包间信息
    merchantInfo: params => {
        return request.post('/api/jagent/merManage/merchantInfo', params);
    },
    //获取pdf文件流
    ParseFile: params => {
        return request.get('/foundation/aliyun/api/oss/ParseFile', {params: params});
    },
}