import { miceService } from '../service/miceService'
import store from '../store';
import {
    getCookie
} from 'tiny-cookie';
export default function  mealInit () {
    return new Promise(function (resolve,reject) {
        
        let params = {
            collection: "cfg-imeal-mealrules",
            useCaching: false,
            cachingKey: "",
            filter: {
                tenant_id: getCookie("tenant_id") || getCookie("tenantId"),
            },
        };
        
        miceService.getMe({}).then((res) => {
            if (res && res.success) {
                store.commit('setMemsg',res.content)
            }
            miceService.getsettings(params).then((res) => {
                if (res && res.success && res.content) {
                    store.commit('setMealRules',res.content)
                    resolve(res.content);
                } else {
                    resolve({});
                }
            })
            .catch((err) => {
                reject(false);
            });
        });
        
    })
}