<template>
    <div>
        <Pop ref="pop" :clickClose="false" bg="'rgba(0,0,0,.1)'">
            <div slot="popContent">
                <span class="alertContent" :style="alertStyle">
                    {{ alertContent }}
                </span>
            </div>
        </Pop>
    </div>
</template>

<script>
export default {
    name: "AlertMessage",
    data() {
        return {
            alertContent: "",
            alertStyle: {},
        };
    },
    watch: {
        "$store.state.alertContent": function () {
            this.alertContent = this.$store.state.alertContent.value;
            this.openPop();
        },
    },
    created() {
        this.alertStyle = {
            marginBottom: window.innerHeight / 2 + "px",
        };
    },
    methods: {
        openPop() {
            this.$refs.pop.changePop2();
        },
    },
};
</script>

<style scoped>
.alertContent {
    background: #000;
    color: #fff;
    font-size: 0.12rem;
    padding: 0.12rem;
    display: inline-block;
    border-radius: 0.1rem;
    max-width: 80%;
}
</style>
