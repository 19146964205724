<template>
    <section class="iDatePickerBox" :style="{ 'z-index': isShow ? zIndex : 0 }">
        <transition name="iDatePicker">
            <div v-if="isShow" class="contentBox" :style="{ 'z-index': zIndex + 10 }">
                <div class="titleBox">
                    <div class="cancelBox">选择时间</div>
                    <div class="confirmBox" @click="onConfirm()">确定</div>
                </div>
                <div class="scrollBox" :style="{ height: popupHeight }">
                    <!-- 年 -->
                    <div class="wheelWrapper" :style="{ width: wheelWidth }" v-if="wheelList.includes('YYYY')">
                        <wheel ref="yearWheel" v-model="modelYear" :dataList="yearList" :rotate="rotate" :top="top" :rowHeight="rowHeight"></wheel>
                    </div>
                    <!-- 月 -->
                    <div class="wheelWrapper" :style="{ width: wheelWidth }" v-if="wheelList.includes('MM')">
                        <wheel ref="monthWheel" v-model="modelMonth" :dataList="monthList" :rotate="rotate" :top="top" :rowHeight="rowHeight"></wheel>
                    </div>
                    <!-- 日 -->
                    <div class="wheelWrapper" :style="{ width: wheelWidth }" v-if="wheelList.includes('DD')">
                        <wheel ref="dayWheel" v-model="modelDay" :dataList="dayList" :rotate="rotate" :top="top" :rowHeight="rowHeight"></wheel>
                    </div>
                    <!-- 时 -->
                    <div class="wheelWrapper" :style="{ width: wheelWidth }" v-if="wheelList.includes('hh')">
                        <wheel ref="hourWheel" v-model="modelHour" :dataList="hourList" :rotate="rotate" :top="top" :rowHeight="rowHeight"></wheel>
                    </div>
                    <!-- 分 -->
                    <div class="wheelWrapper" :style="{ width: wheelWidth }" v-if="wheelList.includes('mm')">
                        <wheel ref="minuteWheel" v-model="modelMinute" :dataList="minuteList" :rotate="rotate" :top="top" :rowHeight="rowHeight"></wheel>
                    </div>
                    <!-- 秒 -->
                    <div class="wheelWrapper" :style="{ width: wheelWidth }" v-if="wheelList.includes('ss')">
                        <wheel ref="secondWheel" v-model="modelSecond" :dataList="secondList" :rotate="rotate" :top="top" :rowHeight="rowHeight"></wheel>
                    </div>
                </div>
                <div class="cancel_Box" @click="onCancel()">取消</div>
            </div>
        </transition>
        <div v-if="isShow" @touchmove.prevent :style="{ 'z-index': zIndex }" class="shade" @click.stop="closeOnClick()"></div>
    </section>
</template>

<script>
import DateTool from './dateTool.js';
import wheel from './wheel.vue';
export default {
    name: "iDatePicker",
    components: {
        wheel
    },
    model: {
        prop: "value",
        event: "valueListener",
    },
    props: {
        // 控制时间弹框打开、关闭，直接使用v-model
        visible: {
            type: Boolean,
            default: false,
        },
        // 业务组件实际使用日期的默认值
        value: {
            type: [String, Number],
            default: ''
        },
        // 是否点击遮罩关闭
        closeModal: {
            type: Boolean,
            default: true,
        },
        // 弹框层级
        zIndex: {
            type: Number,
            default: 30000,
        },
        // 行数
        row: {
            type: Number,
            default: 6
        },
        // 选中行距离顶部的行数
        top: {
            type: Number,
            default: 2
        },
        // 单行的高度
        rowHeight: {
            type: Number,
            default: 40
        },
        // 轮轴每项的偏转角度
        rotate: {
            type: Number,
            default: 18
        },
        // 日期格式 -- 年、月、日 小时、分钟、秒
        format: {
            type: String,
            default: 'YYYY-MM-DD hh:mm:ss'
        },
        // 小时间隔
        hourLimit: {
            type: [String, Number],
            default: 1
        },
        // 分钟间隔
        minuteLimit: {
            type: [String, Number],
            default: 1
        },
        // 秒间隔
        secondLimit: {
            type: [String, Number],
            default: 1
        },
        // 是否显示年月日时分秒文字
        showTxt: {
            type: Boolean,
            default: true
        },
        // 开始时间
        startDate: {
            type: [String, Date],
            default: DateTool.getTime()
        },
        // 结束时间 -- 默认是false，不做结束时间限制
        endDate: {
            type: [String, Date, Boolean],
            default: false
        },
        // 未来多少年范围内, 默认未来10年
        maxYear: {
            type: Number,
            default: 10
        }
    },
    watch: {
        // 业务组件实际使用日期的默认值
        value() {
            this.modelValue = this.value;
        },
        // 当前组件引用日期的默认值，可以在当前组件进行修改，并且反馈给父组件
        modelValue() {
            this.$emit("valueListener", this.modelValue)
        },
        /**
         * 注意的是，在年月日时分秒，分别更新每一列，上级都会影响下一级，但是不用考虑重置跨级，例如更新了年，需要同时更新月日时分秒，
         * 但是在这里只需要手动更新月列表就好，因为wheel采用v-model绑定数据，月列表更新，会重新获取列表第一个值给v-model
         * 然后wheel的v-model的值会更新反馈到当前组件，从而实现链式更新
         */
        // 监听年变化--更新月列表
        modelYear() {
            this.monthList = this.dateToolInstance.initMonth({
                year: this.yearItem ? this.yearItem.val : this.currentDate.getFullYear()
            });
        },
        // 监听月变化--更新日列表
        modelMonth() {
            this.dayList = this.dateToolInstance.initDay({
                year: this.yearItem ? this.yearItem.val : this.currentDate.getFullYear(),
                month: this.monthItem ? this.monthItem.val : this.currentDate.getMonth()
            });
        },
        // 监听日--更新小时列表
        modelDay() {
            this.hourList = this.dateToolInstance.initHour({
                year: this.yearItem ? this.yearItem.val : this.currentDate.getFullYear(),
                month: this.monthItem ? this.monthItem.val : this.currentDate.getMonth(),
                day: this.dayItem ? this.dayItem.val : this.currentDate.getDate()
            });
        },
        // 监听时--更新分钟列表
        modelHour() {
            this.minuteList = this.dateToolInstance.initMinute({
                year: this.yearItem ? this.yearItem.val : this.currentDate.getFullYear(),
                month: this.monthItem ? this.monthItem.val : this.currentDate.getMonth(),
                day: this.dayItem ? this.dayItem.val : this.currentDate.getDate(),
                hour: this.hourItem ? this.hourItem.val : this.currentDate.getHours()
            })
        },
        // 监听分钟--更新秒列表
        modelMinute() {
            this.secondList = this.dateToolInstance.initSecond({
                year: this.yearItem ? this.yearItem.val : this.currentDate.getFullYear(),
                month: this.monthItem ? this.monthItem.val : this.currentDate.getMonth(),
                day: this.dayItem ? this.dayItem.val : this.currentDate.getDate(),
                hour: this.hourItem ? this.hourItem.val : this.currentDate.getHours(),
                minute: this.minuteItem ? this.minuteItem.val : this.currentDate.getMinutes()
            })
        }
    },
    computed: {
        // popup的内容高度取决于row行数
        popupHeight() {
            return `${this.row*40}px`;
        },
        // 滚轮数
        wheelList() {
            return DateTool.formatDate(this.format);
        },
        // 列宽
        wheelWidth() {
            return `${(1/this.wheelList.length)*100}%`
        },
        // 当前年选项
        yearItem() {
            return this.dateToolInstance.allYear().find(e => e.txt == this.modelYear);
        },
        // 当前月选项
        monthItem() {
            return this.dateToolInstance.allMonth().find(e => e.txt == this.modelMonth);
        },
        // 当前日选项
        dayItem() {
            return this.dateToolInstance.allDay().find(e => e.txt == this.modelDay);
        },
        // 当前时选项
        hourItem() {
            return this.dateToolInstance.allHour().find(e => e.txt == this.modelHour);
        },
        // 当前分选项
        minuteItem() {
            return this.dateToolInstance.allMinute().find(e => e.txt == this.modelMinute);
        },
        // 当前秒选项
        secondItem() {
            return this.dateToolInstance.allSecond().find(e => e.txt == this.modelSecond);
        },
        // 当前时间
        currentDate() {
            return new Date(this.startDate.replace(/-/g, '/'));
        }
    },
    data() {
        return {
            dateToolInstance: new DateTool({
                instance: this
            }),
            isShow: false, // 控制弹框
            yearList: [], // 年数组
            monthList: [], // 月数组
            dayList: [], // 日数组
            hourList: [], // 小时数组
            minuteList: [], // 分钟数组
            secondList: [], // 秒数组
            modelValue: '', // 当前时间
            modelYear: '', // 年
            modelMonth: '', // 月
            modelDay: '', // 日
            modelHour: '', // 小时
            modelMinute: '', // 分钟
            modelSecond: '', // 秒
        };
    },
    methods: {
        // plugin入口
        render(options) {
            Object.assign(this.$props, options);
            this.isShow = true;
            this.init();
        },
        // 初始化年、月、日、时、分、秒
        init() {
            this.modelValue = this.value;
            this.yearList = this.dateToolInstance.initYears(); // 年数组
            this.monthList = this.dateToolInstance.initMonth(); // 月数组
            this.dayList = this.dateToolInstance.initDay(); // 日数组
            this.hourList = this.dateToolInstance.initHour(); // 小时数组
            this.minuteList = this.dateToolInstance.initMinute(); // 分钟数组
            this.secondList = this.dateToolInstance.initSecond(); // 秒数组
            this.init_year_month_day();
        },
        // 初始化年月日具体单列的model值
        init_year_month_day() {
            const year_month_day = DateTool.formatDate(this.modelValue || DateTool.getTime());
            const yearItem = this.dateToolInstance.allYear().find(e => e.val == year_month_day[this.wheelList.findIndex(t => t == "YYYY")]);
            const monthItem = this.dateToolInstance.allMonth().find(e => e.val == parseInt(year_month_day[this.wheelList.findIndex(t => t == "MM")])-1);
            const dayItem = this.dateToolInstance.allDay().find(e => e.val == year_month_day[this.wheelList.findIndex(t => t == "DD")]);
            const hourItem = this.dateToolInstance.allHour().find(e => e.val == year_month_day[this.wheelList.findIndex(t => t == "hh")]);
            const minuteItem = this.dateToolInstance.allMinute().find(e => e.val == year_month_day[this.wheelList.findIndex(t => t == "mm")]);
            const secondItem = this.dateToolInstance.allSecond().find(e => e.val == year_month_day[this.wheelList.findIndex(t => t == "ss")]);
            this.modelYear = yearItem ? yearItem.txt: "";
            this.modelMonth = monthItem ? monthItem.txt : "";
            this.modelDay = dayItem ? dayItem.txt : "";
            this.modelHour = hourItem ? hourItem.txt : "";
            this.modelMinute = minuteItem ? minuteItem.txt : "";
            this.modelSecond = secondItem ? secondItem.txt : "";
        },
        // 确定
        onConfirm() {
            this.isShow = false;
            this.updateModelValue();
            this.$emit("onConfirm", this.modelValue);
        },
        // 确定后更新v-model
        updateModelValue() {
            const year = this.yearItem ? this.yearItem.val : this.currentDate.getFullYear();
            const month = this.monthItem ? this.monthItem.val : this.currentDate.getHours();
            const day = this.dayItem ? this.dayItem.val : this.currentDate.getDate();
            const hour = this.hourItem ? this.hourItem.val : this.currentDate.getHours();
            const minute = this.minuteItem ? this.minuteItem.val : this.currentDate.getMinutes();
            const second = this.secondItem ? this.secondItem.val : this.currentDate.getSeconds();

            switch(this.format) {
                case "YYYY-MM-DD hh:mm:ss":
                    this.modelValue = `${year}-${DateTool.formatNum(month+1)}-${DateTool.formatNum(day)} ${hour}:${minute}:${second}`;
                    break;
                case "YYYY-MM-DD hh:mm":
                    this.modelValue = `${year}-${DateTool.formatNum(month+1)}-${DateTool.formatNum(day)} ${hour}:${minute}`;
                    break;
                case "YYYY-MM-DD":
                    this.modelValue = `${year}-${DateTool.formatNum(month+1)}-${DateTool.formatNum(day)}`;
                    break;
                case "YYYY-MM":
                    this.modelValue = `${year}-${DateTool.formatNum(month+1)}`;
                    break;
                case "MM-DD":
                    this.modelValue = `${DateTool.formatNum(month+1)}-${DateTool.formatNum(day)}`;
                    break;
                case "hh:mm:ss":
                    this.modelValue = `${hour}:${minute}:${second}`;
                    break;
                case "hh:mm":
                    this.modelValue = `${hour}:${minute}`;
                    break;
            }
            
        },
        // 取消
        onCancel() {
            this.isShow = false;
            this.$emit("onCancel");
        },
        //是否点击遮罩关闭messageBox
        closeOnClick() {
            this.closeModal && this.onCancel();
        }
    },
};
</script>

<style scoped="scoped" lang="scss">
.iDatePickerBox {
    font-size: 0.14rem !important;
    .contentBox {
        box-sizing: border-box;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        background-color: white;
        .titleBox {
            box-sizing: border-box;
            height: 0.5rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .cancelBox {
                width: 60%;
                height: 0.5rem;
                line-height: 0.5rem;
                text-align: right;
                font-size: .15rem;
            }
            .confirmBox {
                width: 30%;
                height: 0.5rem;
                line-height: 0.5rem;
                color: var(--themeColor)!important;
                text-align: right;
            }
        }
        .scrollBox {
            box-sizing: border-box;
            width: 100%;
            height: calc(100% - 0.5rem);
            padding: 0px 0.1rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .wheelWrapper {
                // width: 33%;
                height: 100%;
            }
            border-bottom: 5px solid #f5f5f5;
        }
        .cancel_Box{
            margin-top: .1rem;
            margin-bottom: .3rem;
        }
    }
    .shade {
        position: fixed;
        top: 0%;
        left: 0%;
        width: 100%;
        height: 100%;
        background: radial-gradient(circle, #333, #000, #000);
        -moz-opacity: 0.4;
        opacity: 0.4;
        filter: alpha(opacity=80);
    }
    .iDatePicker-enter-active {
        animation: iDatePicker-show 0.3s;
    }
    .iDatePicker-leave-active {
        animation: iDatePicker-hide 0.2s;
    }
    @keyframes iDatePicker-show {
        from {
            bottom: -45%;
            opacity: 0;
        }
        to {
            bottom: 0;
            opacity: 1;
        }
    }
    @keyframes iDatePicker-hide {
        from {
            bottom: 0;
            opacity: 1;
        }
        to {
            bottom: -45%;
            opacity: 0;
        }
    }
}
</style>