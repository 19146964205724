<template>
    <Popup v-model="isShow" v-if="isShow" get-container="body" :lazy-render="true">
        <div style="width: 100vh;height: 100vw;">
            <iframe :src="'https://a1wechat-imyprofile.roche.com.cn/web/viewer.html?file=' + pdfUrl " width="100%" height="100%"></iframe>
        </div>
    </Popup>
</template>

<script>
// import pdf from "vue-pdf";
import { Popup } from 'vant';
export default {
    components:{Popup},
    // components:{pdf,Popup},
    name: "pdfPopup",
    props:[
        'pdfUrl'
    ],
    data() {
        return {
            isShow: false
        };
    },
    created() {

    },
    methods: {
        close(){
            this.isShow = false
        },
        // plugin入口
        render(options) {
            Object.assign(this.$props, options);
            this.isShow = true;
        },
        onConfirm(){
            this.$emit("onConfirm", true);
        }
    },
};
</script>

<style lang="scss" scoped>
.WXShadow {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 40px;
    z-index: 3;
    img {
        width: 36%;
        height: 25%;
        float: right;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
    }
    .shadowtext {
        font-size: 0.2rem;
        position: fixed;
        top: 2rem;
        width: 80%;
        background: #fff;
        left: 5%;
        text-align: center;
        padding: 0.15rem;
        padding-bottom: 0.25rem;
        border-radius: 0.1rem;
        box-shadow: 0 0 9px 0px #2a292966;
    div {
        font-weight: 600;
        margin-bottom: 0.2rem;
    }
    span {
        font-size: 0.16rem;
    }
    a {
        display: block;
        width: 60%;
        font-size: 0.16rem;
        background: var(--themeColor);
        color: #fff;
        padding: 0.12rem 0.48rem;
        border-radius: 50px;
        margin: 0 auto;
        margin-top: 0.5rem;
    }
    }
}
    .shadowtext2 {
        width: 80vw;
        font-size: 0.2rem;
        background: #fff;
        margin: 0 auto;
        text-align: center;
        padding: 0.15rem;
        padding-bottom: 0.25rem;
        box-shadow: 0 0 9px 0px #2a292966;
        div {
            font-weight: 600;
            margin-bottom: 0.2rem;
        }
        p {
            font-size: 0.14rem;
            text-align: left;
        }
        .input{
            text-align: left;
            width: calc(85vw - .3rem);
            border: 1px solid #e6e7e9; 
            border-radius: .04rem; 
            font-size: .14rem; 
            padding: 5px .06rem; 
            margin-top: 10px

        }
        .btn{
            display: flex;
            a {
                display: block;
                width: calc(45vw - .3rem);
                font-size: 0.14rem;
                background: var(--themeColor);
                color: #fff;
                padding: 0.12rem 0;
                margin: 0 auto;
                margin-top: 0.35rem;
                border-radius: 0.05rem;
            }
            a:first-child{
                border: 1px solid #e6e7e9;
                background: #fff;
                color: #90939a
            }
        }
    }
</style>
