export default class DateTool {

    constructor(options) { 
        const {
            instance
        } = options || {}
        this.instance = instance;
     }

    // 生成年份
    initYears() {
        var currentDate = new Date(this.instance.startDate.replace(/-/g, '/')), // 起始时间
            maxYear = this.instance.maxYear, // 未来多少年
            year = currentDate.getFullYear(), // 起始年
            yearList = []; // 年数组
        // 是否限制结束时间
        if(this.instance.endDate) {
            var endDate = new Date(this.instance.endDate.replace(/-/g, '/'));
            maxYear = endDate.getFullYear() - year; // 若限制结束时间，那么未来年数=结束年-开始年
        }
        for (let y = 0; y <= maxYear; y++) {
            yearList.push({
                txt: `${year + y}${this.instance.showTxt ? '年' : ''}`,
                val: `${year + y}`
            })
        }
        return yearList;
    }

    // 生成月份
    initMonth(options) {
        var currentDate = new Date(this.instance.startDate.replace(/-/g, '/')),
            minMonth = 0,
            maxMonth = 11,
            monthList = [];
        const {
            year = currentDate.getFullYear() // 年
        } = options || {};
        minMonth = year == currentDate.getFullYear() ? currentDate.getMonth() : 0;
        // 是否限制结束时间
        if(this.instance.endDate) {
            var endDate = new Date(this.instance.endDate.replace(/-/g, '/'));
            maxMonth = year == endDate.getFullYear() ? endDate.getMonth() : 11;
        }
        for (var m = minMonth; m <= maxMonth; m++) {
            monthList.push({
                txt: `${DateTool.formatNum(m + 1)}${this.instance.showTxt ? '月' : ''}`,
                val: m
            })
        }
        return monthList;
    }

    // 生成日
    initDay(options) {
        var currentDate = new Date(this.instance.startDate.replace(/-/g, '/'));
        const {
            year = currentDate.getFullYear(), // 年
            month = currentDate.getMonth() // 月
        } = options || {};
        var dayList = [],
            fnDate = new Date(year, month + 1, 0),
            minDay = 1,
            maxDay = new Date(year, month + 1, 0).getDate();
        minDay = (
            fnDate.getFullYear() == currentDate.getFullYear() && 
            fnDate.getMonth() == currentDate.getMonth()
        ) ? currentDate.getDate() : 1;
        // 是否限制结束时间
        if(this.instance.endDate) {
            var endDate = new Date(this.instance.endDate.replace(/-/g, '/'));
            maxDay = (
                fnDate.getFullYear() == endDate.getFullYear() && 
                fnDate.getMonth() == endDate.getMonth()
            ) ? endDate.getDate() : new Date(year, month + 1, 0).getDate();
        }
        for (let i = minDay; i <= maxDay; i++) {
            dayList.push({
                txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '日' : ''}`,
                val: i
            });
        }
        // if (fnDate.getFullYear() == currentDate.getFullYear() && fnDate.getMonth() == currentDate.getMonth()) {
        //     for (let i = (currentDate.getDate() + 1 > fnDate.getDate() ? 1 : currentDate.getDate()); i <= fnDate.getDate(); i++) {
        //         dayList.push({
        //             txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '日' : ''}`,
        //             val: i
        //         });
        //     }
        // } else {
        //     for (let i = 1; i <= fnDate.getDate(); i++) {
        //         dayList.push({
        //             txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '日' : ''}`,
        //             val: i
        //         });
        //     }
        // }
        return dayList;
    }

    // 生成小时
    initHour(options) {
        var currentDate = new Date(this.instance.startDate.replace(/-/g, '/')),
            minHour = 0,
            maxHour = 23,
            hourList = [];
        const {
            year = currentDate.getFullYear(), // 年
            month = currentDate.getMonth(), // 月
            day = currentDate.getDate(), // 日
        } = options || {};
        minHour = (
            year == currentDate.getFullYear() &&
            month == currentDate.getMonth() &&
            day == currentDate.getDate()
        ) ? currentDate.getHours() : 0;
        // 是否限制结束时间
        if(this.instance.endDate) {
            var endDate = new Date(this.instance.endDate.replace(/-/g, '/'));
            maxHour = (
                year == endDate.getFullYear() && 
                month == endDate.getMonth() &&
                day == endDate.getDate()
            ) ? endDate.getHours() : 23;
        }
        for (let i = minHour; i <= maxHour; i++) {
            if(!(i % this.instance.hourLimit)) {
                hourList.push({
                    txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '时' : ''}`,
                    val: `${DateTool.formatNum(i)}`
                })
            }
        }
        return hourList;
    }

    // 生成分钟
    initMinute(options) {
        var currentDate = new Date(this.instance.startDate.replace(/-/g, '/')),
            minMinute = 0,
            maxMinute = 59,
            minuteList = [];
        const {
            year = currentDate.getFullYear(), // 年
            month = currentDate.getMonth(), // 月
            day = currentDate.getDate(), // 日
            hour = currentDate.getHours(), // 时
        } = options || {};
        minMinute = (
            year == currentDate.getFullYear() && 
            month == currentDate.getMonth() && 
            day == currentDate.getDate() && 
            hour == currentDate.getHours()
        ) ? currentDate.getMinutes() : 0;
        // 是否限制结束时间
        if(this.instance.endDate) {
            var endDate = new Date(this.instance.endDate.replace(/-/g, '/'));
            maxMinute = (
                year == endDate.getFullYear() && 
                month == endDate.getMonth() &&
                day == endDate.getDate() &&
                hour == endDate.getHours()
            ) ? endDate.getMinutes() : 59;
        }
        for (let i = minMinute; i <= maxMinute; i++) {
            if(!(i % this.instance.minuteLimit)) {
                minuteList.push({
                    txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '分' : ''}`,
                    val: `${DateTool.formatNum(i)}`
                })
            }
        }
        return minuteList;
    }

    // 生成秒
    initSecond(options) {
        var currentDate = new Date(this.instance.startDate.replace(/-/g, '/')),
            minSecond = 0,
            maxSecond = 59,
            secondList = [];
        const {
            year = currentDate.getFullYear(), // 年
            month = currentDate.getMonth(), // 月
            day = currentDate.getDate(), // 日
            hour = currentDate.getHours(), // 时
            minute = currentDate.getMinutes(), // 分
        } = options || {};
        minSecond = (
            year == currentDate.getFullYear() && 
            month == currentDate.getMonth() && 
            day == currentDate.getDate() && 
            hour == currentDate.getHours() &&
            minute == currentDate.getMinutes()
        ) ? currentDate.getSeconds() : 0;
        // 是否限制结束时间
        if(this.instance.endDate) {
            var endDate = new Date(this.instance.endDate.replace(/-/g, '/'));
            maxSecond = (
                year == endDate.getFullYear() && 
                month == endDate.getMonth() &&
                day == endDate.getDate() &&
                hour == endDate.getHours() &&
                minute == endDate.getMinutes()
            ) ? endDate.getMinutes() : 59;
        }
        for (let i = minSecond; i <= maxSecond; i++) {
            if(!( i % this.instance.secondLimit)) {
                secondList.push({
                    txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '秒' : ''}`,
                    val: `${DateTool.formatNum(i)}`
                })
            }
        }
        return secondList;
    }

    // 格式化月日
    static formatNum(data) {
        return data > 9 ? `${data}` : `0${data}`
    }

    // 所有年（10年）
    allYear() {
        var date = new Date();
        var year = date.getFullYear();
        var yearList = [];
        for (let y = 0; y < this.instance.maxYear; y++) {
            yearList.push({
                txt: `${year + y}${this.instance.showTxt ? '年' : ''}`,
                val: `${year + y}`
            })
        }
        return yearList;
    }

    // 获取1-12所有月
    allMonth() {
        var monthList = [];
        for (let m = 0; m < 12; m++) {
            monthList.push({
                txt: `${DateTool.formatNum(m + 1)}${this.instance.showTxt ? '月' : ''}`,
                val: m
            })
        }
        return monthList;
    }

    // 获取1-31所有日
    allDay() {
        var dayList = [];
        for (let i = 1; i <= 31; i++) {
            dayList.push({
                txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '日' : ''}`,
                val: i
            })
        }
        return dayList;
    }

    // 获取24小时
    allHour() {
        var hourList = [];
        for (let i = 0; i < 24; i++) {
            hourList.push({
                txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '时' : ''}`,
                val: `${DateTool.formatNum(i)}`
            })
        }
        return hourList;
    }

    // 获取60分钟
    allMinute() {
        var minuteList = [];
        for (let i = 0; i < 60; i++) {
            minuteList.push({
                txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '分' : ''}`,
                val: `${DateTool.formatNum(i)}`
            })
        }
        return minuteList;
    }

    // 获取60秒
    allSecond() {
        var secondList = [];
        for (let i = 0; i < 60; i++) {
            secondList.push({
                txt: `${DateTool.formatNum(i)}${this.instance.showTxt ? '秒' : ''}`,
                val: `${DateTool.formatNum(i)}`
            })
        }
        return secondList;
    }

    static formatDate(data) {
        var arr1 = data.split(" ")
        var arr2 = []
        arr1.map(e => {
            if (e.includes('-')) {
                arr2 = [
                    ...arr2,
                    ...e.split("-")
                ]
            }
            if (e.includes('/')) {
                arr2 = [
                    ...arr2,
                    ...e.split("/")
                ]
            }
            if (e.includes(':')) {
                arr2 = [
                    ...arr2,
                    ...e.split(":")
                ]
            }
        })
        return arr2;
    }

    // 当前时间
    static getTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = DateTool.formatNum(currentDate.getMonth() + 1);
        const day = DateTool.formatNum(currentDate.getDate());
        const hours = DateTool.formatNum(currentDate.getHours());
        const minutes = DateTool.formatNum(currentDate.getMinutes());
        const seconds = DateTool.formatNum(currentDate.getSeconds());
        return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
    }

}