import { SmartStorage } from 'smart-core-util'
import { miceService } from '../service/miceService'
import * as tinyCookie from 'tiny-cookie'

export default class getTenantIds {
    static install() {
        return new Promise(function (resolve) {

            let tenant_code = SmartStorage.get("tenant_code") || tinyCookie.get('tenant');
            console.log("====getTenantId====",tenant_code)
            if (tenant_code == 'crossevent') {
                let tenant_id = tinyCookie.get('tenant_id') || SmartStorage.get('tenant_id')
                let url = `https://spkg.smartmice.cn/configuration/getTenantIds.json`;
                let check;
                let params = {
                    params: {
                        rnd: new Date().getTime(),
                    },
                };
                miceService
                    .Get(url, params)
                    .then((response) => {
                        check = response.teantnIds.find((v) => {
                            return v.id == tenant_id;
                        });
                        SmartStorage.set('checkTenantId', check)
                        resolve(response)
                    }).catch(error => {
                        console.log(error)
                        let teantnIds = [{ "id": "7faf5393-8a70-11ec-89d2-6a0616dfdee4" }, { "id": "1afb2744-d3c9-11eb-a9f0-ca43ba5bd41d" }, { "id": "8fad140e-67b4-11ec-b6d3-2a03e8deecca" }, { "id": "e7a9bfbd-e5fc-11eb-a12a-d2358b3d1d59" }]
                        check = teantnIds.find((v) => {
                            return v.id == tenant_id;
                        });
                        SmartStorage.set('checkTenantId', check)
                        resolve(error)
                    })
            } else {
                resolve(true)
            }
        })


    }
}