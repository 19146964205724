class delay {
    constructor() {
        this.delay = false
        this.delayTime = 300
    }
    init() {
        this.delay = true
        setTimeout(() => {
            this.delay = false
        }, this.delayTime)
    }
    get() {
        return this.delay
    }
}
export default delay