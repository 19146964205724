import Vue from 'vue'
import Vuex from 'vuex'

import meal from './module/meal.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    meal
  }
})
