import axios from "axios";
import qs from "qs";
import { getCookie } from "tiny-cookie";
import SmartStorage from "../storage/index.js";
// import Indicator from 'smart-indicator';
import Toast from "vant/lib/toast";
import "vant/lib/toast/style";
import Dialog from "vant/lib/dialog";
import "vant/lib/dialog/style";

class SmartHttp {
  constructor(options) {
    // 401处理
    this.handle401 = options.handle401 || null;
    // 是否需要加载动画
    this.disableLoadingAnimation = options.disableLoadingAnimation || false;
    // 请求动画
    this.oLoadingAnimation = null;
    // 请求数量
    this.nNumberOfRequests = 0;
    // 请求动画黑名单
    this.aLoadingBlackList = options.aLoadingBlackList || [];
    // 请求原型
    this.request = axios.create({
      baseURL: options.baseURL || SmartStorage.get("uris").gateway,
      timeout: options.timeout || 10000, // 请求超时时间
      headers: {
        "Content-Type": "application/json; charset=utf-8"
      }
    });

    this.injectRequest();
    this.injectResponse();
  }

  // 请求拦截
  injectRequest() {
    this.request.interceptors.request.use(
      config => {
        const token = getCookie("token") || SmartStorage.get("token");
        if (token) {
          config.headers["Authorization"] = "Bearer " + token;
        }

        // 调用后端灰度服务
        const apiVersion =
          SmartStorage.get("apiVersion") || getCookie("apiVersion");
        if (apiVersion) {
          config.headers["Version"] = apiVersion;
        }

        // 协办会（会引擎）权限隔离专用
        const XEventWorldUser =
          SmartStorage.get("X-EventWorld-User") ||
          getCookie("X-EventWorld-User");
        if (XEventWorldUser) {
          config.headers["X-EventWorld-User"] = XEventWorldUser;
        }

        // 账号租户化隔离(暂不统一添加)
        // const tenant_code = SmartStorage.get("tenant") || getCookie("tenant");
        // if (tenant_code) {
        //     config.headers["tenant"] = tenant_code;
        // }

        // get请求添加时间搓
        if (config.method === "get") {
          // config.params = {
          //    ...config.params,
          //    rnd: new Date().getTime(),
          // }
          config.params = Object.assign(config.params || {}, {
            rnd: new Date().getTime()
          });
        }

        if (
          config.data &&
          config.headers["Content-Type"] === "application/x-www-form-urlencoded"
        ) {
          config.data = qs.stringify(config.data);
        }

        //开始动画
        if (
          !this.disableLoadingAnimation &&
          this.aLoadingBlackList.indexOf(config.url) === -1
        ) {
          this.sLoadingAnimation();
        }

        // 小程序上会人员进餐特殊处理
        if(SmartStorage.get('container') == 'miniprogram' && SmartStorage.get('authMobile')){
          config.authMobile = SmartStorage.get('authMobile')
        }
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  // 响应拦截
  injectResponse() {
    this.request.interceptors.response.use(
      response => {
        const {
          data,
          status,
          config: { url }
        } = response;

        // 结束动画
        if (
          !this.disableLoadingAnimation &&
          this.aLoadingBlackList.indexOf(url) === -1
        ) {
          this.eLoadingAnimation();
        }

        // 根据 status 状态拦截
        switch (status) {
          case 200:
            if (process.env.VUE_APP_DEPLOY && process.env.VUE_APP_DEPLOY == "offline") {
              let fullRequestUrl = response.config.url
              let requestUrl
              [".cn/", ".com/"].forEach((postfix) => {
                if (fullRequestUrl.includes(postfix)) {
                  requestUrl = fullRequestUrl.split(postfix)[1]
                }
              })
              let postData = response.config.data
              if (postData) {
                postData = JSON.parse(postData)
              }
            }
            // Todo: 业务状态为false时做处理
            if (data.success === false && !Number(data.code)) {
              Dialog({
                title: "提示",
                message:
                  data.msg || "网络暂时异常,请稍候再试\n如持续报错，请致电客服获得帮助"
              });
            }
            return data;
          default:
            return Promise.reject(response);
        }
      },
      error => {
        const url = error.config.url;
        // 结束动画
        if (
          !this.disableLoadingAnimation &&
          this.aLoadingBlackList.indexOf(url) === -1
        ) {
          this.eLoadingAnimation();
        }

        if (error.response) {
          const {
            data,
            status
          } = error.response;


          // 根据 status 状态拦截
          switch (status) {
            case 400:
              return data;
            case 401:
              if (this.handle401) {
                this.handle401();
                return;
              }
              Dialog({
                title: "提示",
                message:
                  "网络暂时异常,请稍候再试\n如持续报错，请致电客服获得帮助"
              });
              return data;
            default:
              Dialog({
                title: "提示",
                message: data.msg
              });
              return data;
          }
        } else {
          Dialog({
            title: "提示",
            message:
              "网络连接超时,请稍候再试\n如持续报错，请致电客服获得帮助"
          });
          return {};
        }
      }
    );
  }

  /**
   * 开始动画
   */
  sLoadingAnimation() {
    // 如果请求数量等于 0，则开始动画
    if (this.nNumberOfRequests === 0) {
      this.oLoadingAnimation = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中..."
      });
    }
    // 请求数量++
    this.nNumberOfRequests++;
  }

  /**
   * 结束动画
   */
  eLoadingAnimation() {
    // 请求数量--
    this.nNumberOfRequests--;
    // 如果请求数量小于等于 0，则结束动画
    if (this.nNumberOfRequests <= 0) {
      this.oLoadingAnimation.clear();
    }
  }
}

export default SmartHttp;
