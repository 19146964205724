<template>
    <div @scroll="scrollList" id="scrollBar" @touchstart="startScroll($event)" @touchmove="moveScroll($event)"
         @touchend="endScroll($event)">
        <div class="scrollLoading">
            <div class="scrollLoadingBox" :style="loadingStyle">
                <img src="./loading.gif" class="scrollIcon">
            </div>
            <!--<span>加载中,请稍候...</span>-->
        </div>
        <slot name="scrollBar"></slot>
        <div class="scrollLoading" v-if="loadType=='bottom'">
            <img src="./loading.gif">
            <!--<span>加载中,请稍候...</span>-->
        </div>
        <div class="scrollLoading" v-if="loadType=='none'">
            <span>没有更多数据了</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "scrollBar",
        props: [
            'loadType',//加载中出现位置(top/bottom/null) 通过参数改变使加载图标显示隐藏
        ],
        watch: {
            'loadType': function () {
                if (!this.loadType) {
                    this.loadingStyle = {
                        height: '0px'
                    }
                } else if (this.loadType == 'top') {
                    let maxHeight = document.getElementsByClassName('scrollIcon')[0].height;
                    this.loadingStyle = {
                        height: maxHeight + 'px'
                    };
                }
            },
        },
        data() {
            return {
                value: {},
                touchTop: 0,
                touchScrollTop: 0,
                loadingStyle: {}
            }
        },
        created() {
            this.loadingStyle = {
                height: '0px'
            }
        },
        methods: {
            scrollList() {
                let clientHeight = document.getElementById('scrollBar').clientHeight;
                let scrollTop = document.getElementById('scrollBar').scrollTop;
                let scrollHeight = document.getElementById('scrollBar').scrollHeight;
                // if (!scrollTop) {
                //     this.$emit('onTop');
                // }
                if ((clientHeight + scrollTop) >= (scrollHeight - 100)) {
                    this.$emit('onBottom')
                }
            },
            startScroll(e) {
                this.touchTop = e.touches[0].clientY;
                this.touchScrollTop = document.getElementById('scrollBar').scrollTop;
            },
            moveScroll(e) {
                let maxHeight = document.getElementsByClassName('scrollIcon')[0].height;
                if (e.touches[0].clientY > this.touchTop && maxHeight >= (e.touches[0].clientY - this.touchTop)) {
                    this.loadingStyle = {
                        height: e.touches[0].clientY - this.touchTop + 'px'
                    }
                }
            },
            endScroll(e) {
                let end = e.changedTouches[0].clientY;
                let maxHeight = document.getElementsByClassName('scrollIcon')[0].height;
                if ((end - this.touchTop > 50) && this.touchScrollTop == 0) {
                    this.loadingStyle = {
                        height: maxHeight + 'px'
                    };
                    this.$emit('onTop');
                } else {
                    this.loadingStyle = {
                        height: '0px'
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
    @import "./ScrollBar";
</style>