import * as types from '@/store/types'
export default {
    state: {
        orderDetail: {}, // 需求
        memsg: {}, // 登录用户信息
        event: {}, // 传过来的eventData
        xmsPayResult: {},
        refresh: false,
        refreshShop: false,
        fin3Result: {},
        expenseId: '',
        dateObj: {
            monthOn: 0,
            dayOn: 'all',
        },
        address: {},
        shop: {},
        params: {},
        hospitalGPS1: {},
        currentPosition: {},// 当前定位地址信息
        cityAddress: {},// 会议地址信息
        gaoDeShopDetail:{},
        mealRules:{
            newUI: true
        }
    },
    mutations: {
        [types.GAODE_SHOP_DETAIL]: (state, value) => {
            state.gaoDeShopDetail = value
        },
        [types.ORDER_DETAIL]: (state, value) => {
            state.orderDetail = value
        },
        [types.MEMSG]: (state, value) => {
            state.memsg = value
        },
        [types.EVENT]: (state, value) => {
            state.event = value
        },
        [types.XMS_PAY_RESULT](state, value) {
            state.xmsPayResult = value
        },
        [types.FRESH_LIST](state, value) {
            state.refresh = value
        },
        [types.FIN3_RESULT]: (state, value) => {
            state.fin3Result = value
        },
        [types.EXPENSE_ID]: (state, value) => {
            state.expenseId = value
        },
        [types.DATE_OBJ]: (state, value) => {
            state.dateObj = value
        },
        [types.ADDRESS]: (state, value) => {
            state.address = value
        },
        [types.CITY_ADDRESS]: (state, value) => {
            state.cityAddress = value
        },
        [types.SHOP]: (state, value) => {
            state.shop = value
        },
        [types.PARAMS]: (state, value) => {
            state.params = value
        },
        [types.HOSPITAL_GPS]: (state, value) => {
            state.hospitalGPS1 = value
        },
        [types.FRESH_SHOP]: (state, value) => {
            state.refreshShop = value
        },
        [types.CURRENT_POSITION]: (state, value) => {
            state.currentPosition = value
        },
        [types.MEAL_RULES]: (state, value) => {
            state.mealRules = value
        },
        setMealRules(state, value) {
            state.mealRules = value
        },
        setMemsg(state, value) {
            state.memsg = value
        },
    },
    getters: {
        orderDetail: state => state.orderDetail,
        memsg: state => state.memsg,
        event: state => state.event,
        xmsPayResult: state => state.xmsPayResult,
        refresh: state => state.refresh,
        fin3Result: state => state.fin3Result,
        expenseId: state => state.expenseId,
        dateObj: state => state.dateObj,
        address: state => state.address,
        shop: state => state.shop,
        params: state => state.params,
        hospitalGPS1: state => state.hospitalGPS1,
        refreshShop: state => state.refreshShop,
        currentPosition: state => state.currentPosition,
        cityAddress: state => state.cityAddress,
        gaoDeShopDetail: state => state.gaoDeShopDetail,
        mealRules: state => state.mealRules
    }
}