import {
	SmartStorage
} from 'smart-core-util'
import "babel-polyfill"
import iiJsBridge from 'smart-javascript-bridge'
import {
	Toast
} from 'mint-ui'
class NativeJsBridge {
	constructor() {
		this.iJsBridge = new iiJsBridge({
			alert: Toast
		})
		SmartStorage.set('backRouteName', null)
		if (!window.webkit && window.SMGeneral) {
			window.SMGeneral.setBackPressEnable(true);
			window.SMGeneral.setOnBackBressedListener("callBack");
		}
		window['callBack'] = () => {
			this.nativeBackJsBridge();
		}
	}
	setRouter(router) {
		this.router = router
	}
	nativeBackJsBridge() {
		const backRouteName = SmartStorage.get('backRouteName');
		// 		if(routeListeners.length > 0) {
		// 			var key = routeListeners.pop();
		// //				Toast(key)
		// 			//改用bus广播与监听TODO
		// 			switch(key){
		// 				case 'closeMintUIMessageBox':
		// 					this.closeMintUIMessageBox();
		// 					break;
		// 				case 'closeVuxDatetime':
		// 					this.closeVuxDatetime();
		// 					break;
		// 				default:
		// 					this.router.push({'name' : key.name})
		// 			}
		// 			this.router.push({'name' : key.backRouteName})
		// 			this.removeRouteListeners(key)
		// 		};
		// console.log(document.getElementsByClassName('mint-datetime-cancel'))
		// if(dateTime.object) {
		// 	dateTime.close();
		// 	dateTime.set(null)
		// 	return;
		// }
		if (backRouteName == 'root') return
		if (backRouteName == 'ievent') {
			this.close();
			return;
		}
		this.router.push({
			'name': backRouteName
		})
	}
	close() {
		// var sendData = JSON.stringify({
		// 	handle: "reload_event_detail"
		// });

		// this.iJsBridge.call({
		// 	method: 'SMGeneral.setResultToNative',
		// 	postData: sendData
		// });
		// this.iJsBridge.close();
		var sendData = JSON.stringify({ handle: "reload_event_detail" }); 
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler(
				"SmGeneral.closeWebSite",
				sendData
			);
		} else {
			this.iJsBridge.call({
				method: "SMGeneral.setResultToNative",
				postData: sendData
			});
			this.iJsBridge.close();
		}
	}
	//保存当前路由返回事件记录
	setbackRouteName(name) {
		SmartStorage.set('backRouteName', name);
	}
	//安卓返回键，关闭mintUI <MessageBox>
	closeMintUIMessageBox() {
		setTimeout(() => {
			if (document.getElementsByClassName('mint-msgbox-cancel')) {
				document.getElementsByClassName('mint-msgbox-cancel')[0].click();
				this.removeRouteListeners('closeMintUIMessageBox');
			} else {
				this.removeRouteListeners('closeMintUIMessageBox');
			}
		})
	}
	//安卓返回键，关闭Vux <datePicker>
	closeVuxDatetime() {
		setTimeout(() => {
			if ([...document.getElementsByClassName('vux-datetime-cancel')].length > 0) {
				document.getElementsByClassName('vux-datetime-cancel')[0].click();
			}
		})
	}

}
class Layer {
	constructor() {
		this.object = null
	}
	set(name) {
		this.object = name
	}
	get() {
		return this.object
	}
}
class DateTime extends Layer {
	constructor() {
		super()
	}
	close() {
		this.object.close()
	}
}
window.nativeJsBridge = new NativeJsBridge()
window.dateTime = new DateTime()