export const ORDER_DETAIL = 'ORDER_DETAIL'
export const MEMSG = 'MEMSG'
export const EVENT = 'EVENT'
export const XMS_PAY_RESULT = 'XMS_PAY_RESULT'
export const FRESH_LIST = 'FRESH_LIST'
export const FIN3_RESULT = 'FIN3_RESULT'
export const EXPENSE_ID = 'EXPENSE_ID'
export const DATE_OBJ = 'DATE_OBJ'
export const ADDRESS = 'ADDRESS'
export const CITY_ADDRESS = 'CITY_ADDRESS'
export const SHOP = 'SHOP'
export const PARAMS = 'PARAMS'
export const HOSPITAL_GPS = 'HOSPITAL_GPS'
export const FRESH_SHOP = 'FRESH_SHOP'
export const CURRENT_POSITION = 'CURRENT_POSITION'
export const GAODE_SHOP_DETAIL = 'GAODE_SHOP_DETAIL'
export const MEAL_RULES = 'MEAL_RULES'
